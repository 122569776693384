import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-user-badges',
    template: `
        <ng-container *ngFor="let user of users; let f = first; let i = index">
            <app-user-icon *ngIf="users.length <= limit || i < limit - 1"
                           [user]="user"
                           size="xs"
                           classList="pin-shadow"
                           tooltipPlacement="bottom"
                           [tooltipInfo]="getTooltipText(i)"
                           [addOnIcon]="addOnIcons && addOnIcons[i]"
                           [style.marginLeft]="!f ? '-5px' : null">
            </app-user-icon>
        </ng-container>
        <span *ngIf="users?.length > limit"
              class="user-icon user-icon-xs pin-shadow more-indication"
              [style.marginLeft]="limit > 1 ? '-5px' : null"
              ngbTooltip="{{users.length}} {{moreTooltipSuffix}}"
              placement="bottom">
            <span class="caption">{{users.length}}</span>
        </span>
    `,
    styleUrls: ['./user-badges.component.scss'],
})
export class UserBadgesComponent {
    @Input() users: {id: number; first_name: string; last_name: string}[];
    @Input() tooltips: string[];
    @Input() addOnIcons: string[];
    @Input() limit = 3;
    @Input() moreTooltipSuffix = 'users';

    getTooltipText(index: number) {
        return this.tooltips && this.tooltips[index] || null;
    }
}
